import { graphql } from "gatsby"
import React from "react"

import ThreePostsRow from "./ThreePostsRow"

export const fragment = graphql`
  fragment ThreePostsRowFragment on WpPage_Flexlayouts_FlexibleLayouts_ThreePostsRow {
    sectionHeading
    selectedPosts {
      post {
        ... on WpPost {
          id
          categories {
            nodes {
              name
            }
          }
          link
          uri
          title
          date
          postsLayouts {
            postObject {
              ... on WpPost_Postslayouts_PostObject_PostContent {
                blurb
                mainImage {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        width: 700
                        aspectRatio: 1.79
                        quality: 75
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
              ... on WpPost_Postslayouts_PostObject_PostHeader {
                postTitle
                description
                mainImage {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        width: 700
                        aspectRatio: 1.79
                        quality: 75
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFThreePostsRow = ({
  defaultPosts,
  sectionHeading,
  selectedPosts,
  config
}) => {

  return (
  <ThreePostsRow
    sectionHeading={sectionHeading}
    defaultPosts={defaultPosts}
    selectedPosts={selectedPosts}
    config={config}
  />
)}
